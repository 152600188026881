@import "./antd/theme.less";

// .expandedTableRow {
//     background-color: @primary-color-blue-soft;
// }

// thead th {
//     border-top: 1px solid @primary-color-lavender;
//     border-bottom: 2px solid @primary-color-lavender;
// }

.expandedTableRow > td:last-child {
    border-right: 3px solid @primary-color-lavender;
}

.ant-table-expanded-row thead th {
    border-top: 1px solid @primary-color-lavender;
    border-bottom: 1px solid @primary-color-lavender;
}

.ant-table-placeholder td {
    border-bottom-width: 0px !important;
}

.rjsf {
    width: 100%;
}

.truncate {
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
