.expandedTableRow > td:last-child {
  border-right: 3px solid #db46f2;
}
.ant-table-expanded-row thead th {
  border-top: 1px solid #db46f2;
  border-bottom: 1px solid #db46f2;
}
.ant-table-placeholder td {
  border-bottom-width: 0px !important;
}
.rjsf {
  width: 100%;
}
.truncate {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
